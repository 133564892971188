import React, { useEffect, useState } from "react"
import * as JsSearch from "js-search"
import ReactMarkdown from "react-markdown"
import "./ClientSearch.css"
import slugVi from "../utils/slugVi"
import ReactTooltip from "react-tooltip"
import Layout from "./layout"
import { isMobile } from "../utils/utils"

function ClientSearch({ allArticle }) {
  const [isLoading, setIsLoading] = useState(true)
  const [searchResults, setSearchResults] = useState(allArticle)
  const [searchQuery, setSearchQuery] = useState(``)
  const [search, setSearch] = useState(null)

  useEffect(() => {
    rebuildIndex()
  }, [])

  const rebuildIndex = () => {

    const dataToSearch = new JsSearch.Search(`id`)
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy()
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer()
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex(`id`)
    dataToSearch.addIndex(`title`)
    dataToSearch.addIndex(`content`)
    dataToSearch.addDocuments(allArticle)
    setSearch(dataToSearch)
    setIsLoading(false)
  }

  // const searchData = e => {
  //   const queryResult = search.search(e.target.value)
  //   setSearchQuery(e.target.value)
  //   setSearchResults(queryResult)
  // }

  const handleSubmit = e => {
    // console.log('submit')
    const queryResult = search.search(searchQuery)
    setSearchResults(queryResult)
    e.preventDefault()
  }
  const searchTextOnChange = ({ target: { value } }) => {
    setSearchQuery(value)
  }
  // const queryResults = searchQuery===`` ? allArticle:searchResults
  if (isLoading) {
    return <Loading/>
  }
  return (
    <Layout>
      <div style={{ margin: 10 }}>
        <form onSubmit={handleSubmit}>
          <div style={{ margin: 30, display: "flex", justifyContent: "center" }}>
            <input
              autoFocus
              id="Search"
              value={searchQuery}
              onChange={searchTextOnChange}
              placeholder="Tìm kiếm..."
              style={{ maxWidth: 1000, width: 600, padding: 10 }}
            />
          </div>
        </form>
        <div>
          {searchResults.length} bài viết
          <table
            style={{
              width: `100%`,
              borderCollapse: `collapse`,
              borderRadius: `4px`,
              border: `1px solid #d3d3d3`
            }}
          >
            <thead style={{ border: `0px solid #808080` }}>
            <tr>
              {!isMobile() && <span/>}
              <TH name={"title"}/>
              <TH name={"content"}/>
            </tr>
            </thead>

            <tbody className={"content"}>
            {/* eslint-disable */}
            {searchResults.map(({ id, title, content }) => {
              return (
                <Row id={id} title={title} content={content}/>
              )
            })}
            {/* eslint-enable */}
            </tbody>
          </table>

        </div>
      </div>
    </Layout>
  )
}

function Row({ id, title, content }) {
  return (
    <tr key={`row_${id}`}
        data-tip
        data-for={`row_${id}_tip`}
        aria-haspopup='true'
        onClick={() => {
          window.location = `/post/${slugVi(title)}`
        }}
    >
      {
        !isMobile() &&
        <ReactTooltip id={`row_${id}_tip`}
                      overridePosition={(
                        { left, top },
                        currentEvent, currentTarget, node) => {
                        const d = document.documentElement
                        left = Math.min(d.clientWidth - node.clientWidth, left)
                        top = Math.min(d.clientHeight - node.clientHeight, top)
                        left = Math.max(0, left)
                        top = Math.max(0, top)
                        return { top, left }
                      }}
        >
          <h2>{title}</h2>
          <ReactMarkdown source={content}/>
        </ReactTooltip>
      }
      <TD data={title}/>
      <TD data={content} isContent={true}/>
    </tr>
  )
}

const TH = ({ name }) => {
  return (
    <th
      style={{
        textAlign: `left`,
        padding: `5px`,
        fontSize: `14px`,
        fontWeight: 600,
        border: `1px solid #d3d3d3`
      }}
    >
      {name}
    </th>
  )
}

const TD = ({ data, isContent }) => {
  return (
    <td
      style={{
        fontSize: `14px`,
        border: `1px solid #d3d3d3`
      }}
    >
      {isContent ?
        <ReactMarkdown
          source={data.split("\n").slice(0, 3).join("\n")}
        />:
        <h6>{data}</h6>
      }
    </td>
  )
}
const Loading = () => {
  return (
    <div>
      <h1 style={{ marginTop: `3em` }}>Đang load dữ liệu...</h1>
    </div>
  )
}

// const Error = () => {
//   return (
//     <div>
//       <h1 style={{ marginTop: `3em`, textAlign: `center` }}>Có lỗi xảy ra, xin hãy thử lại.</h1>
//     </div>
//   )
//
// }

export default ClientSearch
