import React from "react"
import ClientSearch from "../components/ClientSearch"

const SearchTemplate = props => {
  const { pageContext } = props
  const { allArticleData } = pageContext
  const { allArticle, options } = allArticleData
  return (
    <div>
      <ClientSearch allArticle={allArticle} engine={options}/>
    </div>

  )
}

export default SearchTemplate
